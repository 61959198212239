import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckMoving } from '@fortawesome/free-solid-svg-icons';

const TruckLoading = () => {
  const [loadingText, setLoadingText] = useState('Loading.');

  useEffect(() => {
    const loadingTexts = ['Loading', 'Loading.', 'Loading..', 'Loading...'];
    let index = 0;
    const interval = setInterval(() => {
      setLoadingText(loadingTexts[index]);
      index = (index + 1) % loadingTexts.length;
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100">
      <div className="relative">
        <div className="absolute inset-x-0 top-0 flex justify-center items-center">
          <FontAwesomeIcon
            icon={faTruckMoving}
            className="text-6xl"
            style={{ color: '#5e0101'}}
            beatFade
          />
        </div>
        <div className="mt-20 text-2xl font-semibold text-[#5e0101] font-jawbreak">
          {loadingText}
        </div>
      </div>
    </div>
  );
};

export default TruckLoading;
