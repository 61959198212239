import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEye, faEyeSlash, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faSteam } from '@fortawesome/free-brands-svg-icons';

const UserProfile = ({ requser }) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(requser);
  }, [requser]);

  const steamredirect = () => {
    window.open(user.steamprofileurl);
  };

  const discordredirect = () => {
    window.open(user.discordprofileurl);
  };

  const hide = () => {
    if (user.password) {
      window.open('https://hub.wildhogztrucking.com', '_self');
    }
  };


  if (!user) return <p>None</p>;

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg relative">
        <div style={{ backgroundColor: user.color }} className="h-24 rounded-t-lg"></div>
        <div className="absolute top-16 left-1/2 transform -translate-x-1/2">
          <img
            src={user.avatar}
            alt={user.steamusername}
            className="w-24 h-24 rounded-full border-4 border-white"
          />
        </div>

        <div className="p-8 pt-24 font-poppins">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">{user.steamusername}</h2>
            <div className="flex space-x-4">
              <button className="text-sm text-gray-600 flex items-center space-x-1" onClick={steamredirect}>
                <FontAwesomeIcon icon={faSteam} style={{ color: '#00adee' }} size="xl" />
              </button>
              <button className="text-sm text-gray-600 flex items-center space-x-1" onClick={discordredirect}>
                <FontAwesomeIcon icon={faDiscord} style={{ color: '#5865F2' }} size="xl" />
              </button>
            </div>
          </div>
          <form method="POST" action="https://api.wildhogztrucking.com/update/profile">
            <div className="mb-4">
              {user.steamusername ? (
                <label className="block text-sm font-medium text-gray-700">Username </label>
              ) : (
                <label className="block text-sm font-medium text-gray-700">Username <span className='text-red-600'>*</span></label>
              )}
              <div className="mt-1 flex items-center border border-gray-300 rounded-md py-2 px-3">
                <FontAwesomeIcon icon={faUser} className="text-gray-400 mr-2" style={{ color: user.color }} />
                <input
                  type="text"
                  placeholder='John Doe'
                  name='username'
                  required
                  className="flex-grow focus:outline-none"
                  defaultValue={user.steamusername}
                />
              </div>
            </div>

            <div className="mb-4">
              {user.email ? (
                <label className="block text-sm font-medium text-gray-700">Email </label>
              ) : (
                <label className="block text-sm font-medium text-gray-700">Email <span className='text-red-600'>*</span></label>
              )}
              <div className="mt-1 flex items-center border border-gray-300 rounded-md py-2 px-3">
                <FontAwesomeIcon icon={faEnvelope} className="text-gray-400 mr-2" style={{ color: user.color }} />
                <input
                  type="email"
                  placeholder='johndoe@xyz.com'
                  name='email'
                  required
                  className="flex-grow focus:outline-none"
                  defaultValue={user.email}
                />
              </div>
            </div>

            <div className="mb-4">
              {user.password ? (
                <label className="block text-sm font-medium text-gray-700">Password </label>
              ) : (
                <label className="block text-sm font-medium text-gray-700">Password <span className='text-red-600'>*</span></label>
              )}
              <div className="mt-1 flex items-center border border-gray-300 rounded-md py-2 px-3">
                <FontAwesomeIcon icon={faLock} className="text-gray-400 mr-2" style={{ color: user.color }} />
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Password'
                  name='password'
                  minLength={8}
                  required
                  pattern="(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}"
                  title="Password must contain at least one capital letter, one special character, and one number."
                  className="flex-grow focus:outline-none"
                  defaultValue={requser.password}
                />
                <button type="button" onClick={togglePasswordVisibility} className="ml-2">
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} style={{ color: user.color }} />
                </button>
              </div>
            </div>

            <div className="flex justify-end space-x-4 mt-6">
              <button style={{ color: user.color }} className="px-4 py-2 rounded-md" onClick={hide}>OK</button>
              <button style={{ backgroundColor: user.color }} className="text-white px-4 py-2 rounded-md" type='Submit'>Save changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;