import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faSteam } from '@fortawesome/free-brands-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Login = () => {

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);


  const steamlogin = () => {
    window.open('https://api.wildhogztrucking.com/auth/login/steam', '_self');
  };

  const discordlogin = () => {
    window.open('https://api.wildhogztrucking.com/auth/login/discord', '_self');
  };

  return (
    <div className="min-h-screen flex">
      <span className='font-jawbreak text-5xl ml-2 mt-1'>WHT</span>
      {/* Left Side */}
      <div className="w-1/2 bg-white p-16 flex flex-col justify-center items-center">
        <div className="mb-8">
          <h1 className="text-9xl font-bold mb-4 font-poppins">Hi there!</h1>
          <p className="font-bold text-center font-poppins text-[#5e0101]">
            Welcome to Wild Hogz Trucking. Drivers Hub
          </p>
        </div>
        <div className="mb-4 w-3/5">
          <div onClick={steamlogin} className="w-full bg-black cursor-pointer text-white border border-stone-900 rounded-lg py-3 flex items-center justify-center hover:bg-[#00adee] font-poppins">
            <FontAwesomeIcon
              icon={faSteam}
              size="xl"
              style={{ color: '#FFFFFF' }}
              className="h-6 mr-2"
            />
            Login in with Steam
          </div>
        </div>
        <div className="flex items-center my-1 w-3/5"></div>
        <div className="mb-2 w-3/5">
          <div onClick={discordlogin} className="w-full cursor-pointer bg-black text-white border border-stone-900 rounded-lg py-3 flex items-center justify-center hover:bg-[#5865F2] font-poppins">
            <FontAwesomeIcon
              icon={faDiscord}
              size="xl"
              style={{ color: '#FFFFFF' }}
              className="h-6 mr-2"
            />
            Login with Discord
          </div>
        </div>

        <div className="flex items-center my-4 w-3/5">
          <div className="flex-grow border-t border-gray-300"></div>
          <span className="mx-4 text-gray-500 font-poppins">or</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>
        <form className="w-3/5" method="POST" action={'https://api.wildhogztrucking.com/auth/login/local'}>
          <div className="mb-4">
            <input
              type="email"
              name="email"
              required
              placeholder="Your email"
              className="w-full border border-gray-300 rounded-lg py-3 px-4 focus:outline-none focus:border-black font-poppins"
            />
          </div>
          <div className="mb-4 relative">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              minLength={8}
              required
              pattern="(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}"
              title="Password must contain at least one capital letter, one special character, and one number."
              placeholder="Password"
              className="w-full border border-gray-300 rounded-lg py-3 px-4 focus:outline-none focus:border-black font-poppins pr-10"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500"
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>
          <div className="mb-4 text-right">
            <a href="/" className="text-sm text-[#5e0101] font-poppins">Forgot password?</a>
          </div>
          <button
            type="submit"
            className="w-full cursor-pointer bg-black text-white border border-stone-900 rounded-lg py-3 flex items-center justify-center hover:bg-[#5e0101] font-poppins"
          >
            Sign In
          </button>
        </form>
        <div className="mt-8 text-center">
          <p className="text-gray-500 font-poppins">
            Don't have an account?{' '}
            <a href="/signup" className="text-[#5e0101]">
              Sign up
            </a>
          </p>
        </div>
      </div>
      {/* Right Side */}
      <div className="bg-[url(https://static.truckersmp.com/images/vtc/gallery/at-the-mile-logistics.1712016715.jpg)] bg-cover bg-center w-2/4 min-h-screen flex items-center justify-center">
        <div className="text-center">
          <img
            src="https://static.truckersmp.com/images/vtc/logo/48355.1708291371.png"
            alt="Company Logo"
            className="w-40 h-40 mx-auto mb-2"
          />
          <h1 className="text-5xl text-white font-jawbreak mb-2">
            Wild Hogz Trucking
          </h1>
          <p className="text-xl text-white font-poppins">Drive to be Wild</p>
        </div>
      </div>
    </div>
  );
};

export default Login;