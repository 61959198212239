import React from 'react';
import Sidebar from '../components/sidebar';
import Header from '../components/header';
import PerformanceGraph from '../components/performancegraph';
import JobList from '../components/joblist';
import ActivityFeed from '../components/activityfeed';
import ProfileCard from '../components/profilecard';
import UserProfile from '../components/userprofile';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import TruckLoading from '../components/truckloading';
import axios from 'axios';

const queryClient = new QueryClient();

const Dashboard = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <DashboardStruct />
    </QueryClientProvider>
  );
};

const DashboardStruct = () => {
  const { isLoading, error, data } = useQuery('userData', () =>
    axios.get('https://api.wildhogztrucking.com/data/getuserdata', {
      withCredentials: true,
      headers: { 'Access-Control-Allow-Origin': 'https://hub.wildhogztrucking.com' },
    })
  );

  const { isLoading: isJobLoading, error: jobError, data: jobDataResponse } = useQuery('jobData', () =>
    axios.get('https://api.wildhogztrucking.com/data/getalljobs', {
      withCredentials: true,
      headers: { 'Access-Control-Allow-Origin': 'https://hub.wildhogztrucking.com' },
    })
  );

  if (isLoading || isJobLoading) return <TruckLoading />;
  if (error || jobError) window.location.replace('https://hub.wildhogztrucking.com/signin');

  const userData = data?.data;
  const jobData = jobDataResponse?.data?.map((job) => {
    if (job.Status === 'completed') {
      return {
        'Date/Time': job.CompletedTime,
        Driver: job.DriverName,
        'Source - Destination': job.SourceCityName + ' - ' + job.DestinationCityName,
        Cargo: job.CargoName,
        'Driven Distance': Math.floor(job.DrivenDistance * 0.62137) + ' mi',
        Revenue: '$ ' + job.Revenue,
      };
    }
    return null;
  }).filter(job => job !== null) || [];

  return (
    <div className="flex">
      <Sidebar />
      <div className="ml-[105px] flex-grow">
        {userData && userData.requser.password ? null : <UserProfile requser={userData.requser} />}
        <Header requser={userData.requser} />
        <div className="min-h-screen">
          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-2 mt-2">
              <PerformanceGraph />
              <div className="container mx-auto">
                {jobDataResponse ? <JobList jobData={jobData} /> : null}
              </div>
            </div>
            <div className="mt-3">
              <ProfileCard requser={userData.requser} />
              <ActivityFeed />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;