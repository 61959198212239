import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

function Protected(props) {
    const { Component } = props;
    const navigate = useNavigate();
    const protocol = window.location.protocol
    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const res = await axios.get('https://api.wildhogztrucking.com/data/getuserdata', { headers: {
                    'Content-Type': 'application/json'
                  },
                  withCredentials: true
                 });
                if (res.data.user['steamAuthenticated'] && res.data.user['discordAuthenticated']) {
                    navigate(Component);
                } else {
                    navigate('/signin');
                }
            } catch (err) {
                navigate('/signin');
            }
        };

        checkAuthentication();
    }, [Component, navigate, protocol]);

    return (
        <div>
            <Component />
        </div>
    );
}

export default Protected;