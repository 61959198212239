import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Login from './pages/login';
import Register from './pages/register';
import Dashboard from './pages/dashboard';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Protected from './components/protected';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Protected Component={Dashboard}/>}/>
        <Route path="/signup" element={<Register />}/>
        <Route path="/signin" element={<Login />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);